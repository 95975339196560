import styles from "./contact.module.css";
import phoneImg from "../../assets/images/phone.svg";
import emailImg from "../../assets/images/envelope.svg";
function Contact() {
  return (
    <div id="contact">
      <div className={styles.regulation} />
      <div className={styles.main}>
        <div className={styles.profile}>
          <div>
            <a href="tel:600 559 779">
              <img src={phoneImg} className={styles.bigPhone} />
            </a>
            <div>
              <p className={styles.name}>Artur Chraniuk</p>
              <p className={styles.role}>Sprzedaż nawozów</p>
            </div>
            <div>
              <ul className={styles.contact}>
                <li className={styles.list}>
                  <a href="tel:600 559 779">
                    <img src={phoneImg} />
                    600 559 779
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.profile}>
          <div>
            <a href="tel:506 721 980">
              <img src={phoneImg} className={styles.bigPhone} />
            </a>
            <div>
              <p className={styles.name}>Mirosław Koszewski</p>
              <p className={styles.role}>Skład Mlęcin</p>
            </div>
            <div>
              <ul className={styles.contact}>
                <li className={styles.list}>
                  <a href="tel:506 721 980">
                    <img src={phoneImg} />
                    506 721 980
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.profile}>
          <div>
            <a href="tel:514 226 681">
              <img src={phoneImg} className={styles.bigPhone} />
            </a>
            <a href="mailto:jackowicz@wp.pl">
              <img src={emailImg} className={styles.bigEmail} />
            </a>
            <div>
              {/* <p className={styles.name}>Kamil Koszewski</p> */}
              <p className={styles.name}>Przemysław Jackowicz</p>
              <p className={styles.role}>Skład Zakręt</p>
            </div>
            <div>
              <ul className={styles.contact}>
                <li className={styles.list}>
                  <a href="tel:507 952 233">
                    <img src={phoneImg} />
                    507 952 233
                  </a>
                </li>
                <li className={styles.list}>
                  <a href="mailto:jackowicz@wp.pl">
                    <img src={emailImg} /> jackowicz@wp.pl
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
